.day-tab {
  display: flex;
  width: 360px;
  align-items: center;
  margin-bottom: 20px;

  h4 {
    margin: 0;
    padding-right: 10px;
    width: 60px;
  }
}

.day-details {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ddd;
  text-align: center;
  width: 300px;

  p {
    margin: 0.8rem;
  }

  .temp {
    display: flex;
    border-top: 1px solid #ddd;
    justify-content: space-evenly;
  }
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-transform: capitalize;
  }
}
